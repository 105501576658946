<template>
  <v-card outlined class="pa-5 fill-height overflow-y-auto" max-height="400">
    <v-card-title class="text-h5 pt-0 px-0">
      Offene Rechnungen ({{ invoicesCount }})
      <v-spacer />
      <v-text-field
        v-model="search"
        dense
        outlined
        hide-details
        label="Suchen"
      />
    </v-card-title>
    <v-sheet outlined rounded class="overflow-y-auto">
      <v-data-table
        :headers="dataTableHeaders"
        :items="invoices"
        :footer-props="dataTableFooterProps"
        :loading="fetching"
        :items-per-page="-1"
        :search="search"
        hide-default-footer
        loading-text="Rechnungen werden geladen..."
        no-data-text="Keine offenen Rechnungen gefunden."
      >
        <template v-slot:item.identifier="{ item }">
          <router-link :to="`/backend/invoices/${item.id}`">
            {{ item.identifier }}
          </router-link>
        </template>
        <template v-slot:item.date="{ item }">
          {{ formatDate(item.date) }}
        </template>
        <template v-slot:item.due_date="{ item }">
          {{ formatDate(item.due_date) }}
        </template>
        <template v-slot:item.customer="{ item }">
          <router-link
            v-if="item.customer.anrede === 'Firma' && item.customer.firma"
            :to="`/backend/users/customers/${item.customer.uuid}/details`"
            v-html="truncateString(item.customer.firma, 50)"
          ></router-link>
          <router-link
            v-else
            :to="`/backend/users/customers/${item.customer.uuid}/details`"
            v-html="
              truncateString(
                `${item.customer.vorname} ${item.customer.nachname}`,
                50
              )
            "
          >
          </router-link>
        </template>
        <template v-slot:item.net_total="{ item }">
          {{ numberToCurrency(item.net_total) }}
        </template>
        <template v-slot:item.gross_total="{ item }">
          <div class="grey--text">
            {{ numberToCurrency(item.gross_total) }}
            <v-menu
              v-if="item.status === 'partially_paid'"
              left
              offset-y
              open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  ({{ getPartialPaymentAmount(item) }})
                </div>
              </template>
              <v-list>
                <v-list-item
                  v-for="(payment, index) in item.payments"
                  :key="`payment-${index}`"
                  dense
                >
                  <v-list-item-title>
                    Teilzahlung: {{ numberToCurrency(payment.amount) }} -
                    {{ formatDate(payment.payment_date) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-sheet>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import { DateTime } from "luxon";
import { mdiArrowCollapseLeft, mdiArrowCollapseRight } from "@mdi/js";

export default {
  name: "DashboardWidgetOffeneRechnungen",
  data: () => ({
    fetching: false,
    error: null,
    invoices: [],
    meta: {},
    search: null,
    dataTableFooterProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: mdiArrowCollapseLeft,
      lastIcon: mdiArrowCollapseRight,
      itemsPerPageOptions: [20, 50, -1],
    },
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    invoicesCount() {
      return this.meta.count ? this.meta.count : 0;
    },
    dataTableHeaders() {
      return [
        { text: "Nr.", value: "identifier", sortable: false },
        { text: "Datum", value: "date", sortable: false },
        { text: "Fällig", value: "due_date", sortable: false },
        {
          text: "Kunde",
          value: "customer",
          sortable: false,
        },
        { text: "Netto", value: "net_total", align: "end", sortable: false },
        { text: "Brutto", value: "gross_total", align: "end", sortable: false },
      ];
    },
  },
  async mounted() {
    await this.fetchInvoices();
  },
  methods: {
    async fetchInvoices() {
      const payload = {
        params: {
          status: ["created", "sent"],
        },
      };

      this.fetching = true;
      await ApiService.fetchInvoices(payload)
        .then((res) => {
          this.invoices = res.data.collection;
          this.meta = res.data.meta;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    customFilter(value, search, item) {
      if (!search) return true;

      const searchValue = String(search).toLowerCase();
      const fieldValue = item[value];
      const customer = item.customer || {};

      const firma =
        typeof customer.firma === "string" ? customer.firma.toLowerCase() : "";
      const fullName = `${customer.vorname || ""} ${
        customer.nachname || ""
      }`.toLowerCase();

      return (
        firma.includes(searchValue) ||
        fullName.includes(searchValue) ||
        String(fieldValue || "")
          .toLowerCase()
          .includes(searchValue)
      );
    },
    numberToCurrency(number) {
      if (typeof number === "number") {
        return number.toLocaleString(undefined, {
          style: "currency",
          currency: "EUR",
        });
      }
      return 0;
    },
    truncateString(text, max) {
      return text.length > max ? text.slice(0, max - 1) + "&hellip;" : text;
    },
    formatDate(date) {
      return date
        ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
        : "";
    },
  },
};
</script>

<style scoped></style>
